<script setup lang="ts">
import { ProductEnum, type ProductCode } from '~/config';

const props = defineProps<{productCode?: ProductCode | null}>()
const { meta } = useContent();

const currentMetaTags = computed(() => {
  switch (props?.productCode) {
    case ProductEnum.accidentes:
      return meta.value.products.accidentes
    case ProductEnum.auto:
      return meta.value.products.auto
    case ProductEnum.bici:
      return meta.value.products.bici
    case ProductEnum.enfermedadesGraves:
      return meta.value.products["enfermedades-graves"]
    case ProductEnum.funeraria:
      return meta.value.products.funeraria
    case ProductEnum.gamer:
      return meta.value.products.gamer
    case ProductEnum.hogar:
      return meta.value.products.hogar
    case ProductEnum.mascotas:
      return meta.value.products.mascotas
    case ProductEnum.moto:
      return meta.value.products.moto
    case ProductEnum.tech:
      return meta.value.products.tech
    case ProductEnum.viajes:
      return meta.value.products.viajes
    case ProductEnum.vida:
      return meta.value.products.vida
    default:
      return meta.value.home
  }
})
useSeoMeta(currentMetaTags.value);
</script>

<template>
  <div></div>
</template>